.card-block {
  border-style: solid;
  border-width: 1px 6px 1px 6px;
  border-color: $grey-4;
  &:not(.card-block-border) {
    background-color: $blue-grey-1;
  }
}

.card-block-primary {
  border-color: $grey-4 $primary;
  &:not(.card-block-border) {
    background-color: $red-1;
  }
}

.card-block-accent {
  border-color: $grey-4 $accent;
  &:not(.card-block-border) {
    background-color: $blue-1;
  }
}

.card-block-positive {
  border-color: $grey-4 $positive;
  &:not(.card-block-border) {
    background-color: $green-1;
  }
}
