body {
  &.body--light {
    background-color: $background;
    color: $color;
  }
}

#sidebar {
  overflow: hidden;
  background-color: $sidebar-bg;
  color: $sidebar-color;

  .q-icon {
    transition: all 0.3s;
  }

  #sidebar-menu {
    padding-bottom: 1rem;

    border-bottom: 4px solid $red-15;
    border-top: 4px solid $red-15;

    .q-item {
      color: $sidebar-color;

      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
      }

      &::after {
        background-color: $sidebar-active-bg;
        transition: width 0.3s;
      }

      &::before {
        background-color: $sidebar-active-color;
        z-index: 2;
        transition: width 0.3s ease-in 0.2s;
      }

      &::hover {
        background-color: $sidebar-hover-bg;
        color: $sidebar-hover-color;
      }

      &.q-router-link--active,
      &.q-router-link--exact-active,
      &.q-item--active {
        position: relative;

        .q-item__section {
          position: relative;
          z-index: 3;
        }

        &::after {
          width: 100%;
        }

        &::before {
          width: 3px;
        }

        color: $sidebar-active-color;

        .q-icon {
          color: $primary;
        }
      }

      .q-item__section--avatar {
        min-width: 0;
      }
    }
  }

  #sidebar-header {
    .q-expansion-item__content {
      z-index: 5000;
    }
  }

  #sidebar-manager {
    border-bottom: 4px solid $red-15;
  }
}

.q-drawer--mini {
  #sidebar {
    .q-item {
      .q-item__section--avatar {
        .q-icon {
          font-size: 1.7rem;
        }
      }

      padding-left: 0 !important;
    }

    .q-expansion-item__content {
      display: block;

      .q-item__section--avatar {
        .q-icon {
          font-size: 1.5em;
        }
      }
    }
  }
}
