body.body--light {
  background-color: #e4e5e6;
  color: #333333;
}

#sidebar {
  overflow: hidden;
  background-color: #1a202e;
  color: #97a6ba;
}
#sidebar .q-icon {
  transition: all 0.3s;
}
#sidebar #sidebar-menu {
  padding-bottom: 1rem;
  border-bottom: 4px solid #f70d00;
  border-top: 4px solid #f70d00;
}
#sidebar #sidebar-menu .q-item {
  color: #97a6ba;
}
#sidebar #sidebar-menu .q-item::after, #sidebar #sidebar-menu .q-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
}
#sidebar #sidebar-menu .q-item::after {
  background-color: rgba(0, 0, 0, 0.3);
  transition: width 0.3s;
}
#sidebar #sidebar-menu .q-item::before {
  background-color: #e4e5e6;
  z-index: 2;
  transition: width 0.3s ease-in 0.2s;
}
#sidebar #sidebar-menu .q-item::hover {
  background-color: rgba(255, 255, 255, 0.12);
  color: #e4e5e6;
}
#sidebar #sidebar-menu .q-item.q-router-link--active, #sidebar #sidebar-menu .q-item.q-router-link--exact-active, #sidebar #sidebar-menu .q-item.q-item--active {
  position: relative;
  color: #e4e5e6;
}
#sidebar #sidebar-menu .q-item.q-router-link--active .q-item__section, #sidebar #sidebar-menu .q-item.q-router-link--exact-active .q-item__section, #sidebar #sidebar-menu .q-item.q-item--active .q-item__section {
  position: relative;
  z-index: 3;
}
#sidebar #sidebar-menu .q-item.q-router-link--active::after, #sidebar #sidebar-menu .q-item.q-router-link--exact-active::after, #sidebar #sidebar-menu .q-item.q-item--active::after {
  width: 100%;
}
#sidebar #sidebar-menu .q-item.q-router-link--active::before, #sidebar #sidebar-menu .q-item.q-router-link--exact-active::before, #sidebar #sidebar-menu .q-item.q-item--active::before {
  width: 3px;
}
#sidebar #sidebar-menu .q-item.q-router-link--active .q-icon, #sidebar #sidebar-menu .q-item.q-router-link--exact-active .q-icon, #sidebar #sidebar-menu .q-item.q-item--active .q-icon {
  color: #e2001a;
}
#sidebar #sidebar-menu .q-item .q-item__section--avatar {
  min-width: 0;
}
#sidebar #sidebar-header .q-expansion-item__content {
  z-index: 5000;
}
#sidebar #sidebar-manager {
  border-bottom: 4px solid #f70d00;
}

.q-drawer--mini #sidebar .q-item {
  padding-left: 0 !important;
}
.q-drawer--mini #sidebar .q-item .q-item__section--avatar .q-icon {
  font-size: 1.7rem;
}
.q-drawer--mini #sidebar .q-expansion-item__content {
  display: block;
}
.q-drawer--mini #sidebar .q-expansion-item__content .q-item__section--avatar .q-icon {
  font-size: 1.5em;
}