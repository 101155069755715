.q-table-selectable {
  td:first-child,
  th:first-child {
    padding: 0.4rem;

    .q-checkbox__inner--falsy > .q-checkbox__bg {
      background-color: $blue-grey-1;
    }
  }

  &.q-table--dense {
    td:first-child,
    th:first-child {
      padding: 0rem;
    }
  }

  td:first-child {
    background-color: $grey-3;
    border-right: 1px solid #dbdbdb;
  }
}

.q-table__top {
  background-color: $blue-grey-14;
}

.q-table-action {
  background-color: $blue-grey-6;
  color: $white;

  > .q-btn__content {
    > .q-icon {
      &.on-left {
        color: $blue-grey-11;
      }
    }
  }
}

.q-table-action-dropdown {
  background-color: $blue-grey-10;
  color: $white;

  .q-checkbox__inner--falsy {
    .q-checkbox__bg {
      border-color: $blue-grey-6;
    }
  }
}

.q-table {
  td {
    &.q-td-input {
      .q-field__native {
        padding: 0;
      }
    }
  }

  th {
    &.q-th-filterable {
      padding: 0.3rem;
      min-width: 100px;

      .q-field__append,
      .q-field__prepend,
      .q-field__control {
        height: 32px;
        margin-bottom: 0;
      }

      .q-field__native {
        padding: 0;
      }
    }
  }
}
